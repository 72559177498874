import { css, StyleSheet } from 'aphrodite'
import { Font, Theme } from '../Styles'

interface IRow {
  label: string
  value: string
  icon: string
  vertical?: boolean
}

interface IProps {
  title: string
  onEdit?(): void
  rows: IRow[]
}

export default function DetailsCard(props: IProps) {
  const { title, onEdit, rows } = props

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.headerRow)}>
        <span className={css(styles.title)}>{title}</span>
        {!!onEdit && (
          <button className={css(styles.editButton)} onClick={onEdit}>
            {'Edit'}
          </button>
        )}
      </div>
      <div className={css(styles.card)}>
        {rows.map((row, i) => {
          return (
            <div key={row.label} className={css(row?.vertical ? styles.itemColumn : styles.itemRow, i !== 0 && styles.divider)}>
              <div className={css(styles.itemLabelRow)}>
                <img className={css(styles.icon)} src={row.icon} alt="" />
                <div className={css(styles.itemLabel)}>{row.label}</div>
              </div>
              <div className={css(row?.vertical ? styles.itemValueVertical : styles.itemValue)}>{row.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {},
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: Font.Header,
    fontWeight: 400,
    fontSize: 20,
  },
  editButton: {
    backgroundColor: 'transparent',
    color: '#80B549',
    borderColor: '#80B549',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 32,
    width: 65,
  },
  card: {
    border: '0.5px solid #BBBDC0',
    borderRadius: 8,
    backgroundColor: Theme.cardColor,
    width: '100%',
    marginBottom: 16,
    marginTop: 16,
  },
  divider: {
    borderTopWidth: 1,
    borderTopColor: '#BBBDC0',
    borderTopStyle: 'dashed',
  },
  itemRow: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 20,
  },
  itemColumn: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 20,
  },
  itemLabelRow: {
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  itemLabel: {
    fontFamily: Font.Header,
    fontWeight: 400,
    fontSize: 18,
  },
  itemValue: {
    fontFamily: Font.Normal,
    fontWeight: 400,
    fontSize: 14,
  },
  itemValueVertical: {
    fontFamily: Font.Normal,
    fontWeight: 400,
    fontSize: 14,
    marginTop: 6,
    marginLeft: 24,
  },
})
