import { css, StyleSheet } from 'aphrodite'
import confetti from 'canvas-confetti'
import { useEffect, useMemo } from 'react'
import DetailsCard from '../components/DetailsCard'
import Header from '../components/Header'
import CalendarIcon from '../icons/calendar.png'
import ClockIcon from '../icons/clock.png'
import CommandIcon from '../icons/command.png'
import MessagesIcon from '../icons/messages.png'
import StarIcon from '../icons/star.png'
import UserIcon from '../icons/user.png'
import { useStateValue } from '../state/State'
import { Font, Theme } from '../Styles'
import { formatDate, formatTime } from '../Utils'

export default function AppointmentScheduledPage() {
  const [{ appointment, note }] = useStateValue()

  useEffect(() => {
    confetti()
  }, [])

  const date = useMemo(() => {
    return formatDate(appointment?.start)
  }, [appointment])

  const time = useMemo(() => {
    return formatTime(appointment?.start)
  }, [appointment])

  const detailsCardRows = useMemo(() => {
    const rows = [
      { label: 'Date', value: date, icon: CalendarIcon, vertical: false },
      { label: 'Time', value: time, icon: ClockIcon, vertical: false },
    ]
    if (!!note) {
      rows.push({ label: 'Project Detail', value: note, icon: ClockIcon, vertical: true })
    }
    return rows
  }, [date, time, note])

  return (
    <div className={css(styles.container)}>
      <Header title={"Congratulations! You're Scheduled"} subtitle={"We Can't Wait to Meet You."} />
      <DetailsCard title="Scheduled At" rows={detailsCardRows} />
      <div>
        <div className={css(styles.title)}>{"Here's what's next:"}</div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.icon)} src={MessagesIcon} alt="" />
          <div className={css(styles.text)}>{"You'll receive a confirmation message from your Floor Coverings International Design Associate."}</div>
        </div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.icon)} src={UserIcon} alt="" />
          <div className={css(styles.text)}>{'If your plans change, contact your local Design Associate.'}</div>
        </div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.icon)} src={CommandIcon} alt="" />
          <div className={css(styles.text)}>
            {'Learn more about our process and product options '}
            <a href="https://floorcoveringsinternational.com/our-process/" target={'_blank'} rel="noreferrer">
              here
            </a>
            {'.'}
          </div>
        </div>
        {false && (
          <div className={css(styles.vanIconContainer)}>
            <img className={css(styles.vanIcon)} src={process.env.PUBLIC_URL + '/img/van.png'} alt="" />
          </div>
        )}
        <div className={css(styles.title)}>You deserve a better flooring update experience. With FCI - The Stars of Flooring &#8480;, you get:</div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.starIcon)} src={StarIcon} alt="" />
          <div className={css(styles.text)}>
            <b>Mobile Flooring Showroom &#xae;</b> - Free in-home consultations with over 3,000 samples brought straight to your home.
          </div>
        </div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.starIcon)} src={StarIcon} alt="" />
          <div className={css(styles.text)}>
            <b>A local team you can trust</b> - A local and accountable team to manage your project from beginning to end.
          </div>
        </div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.starIcon)} src={StarIcon} alt="" />
          <div className={css(styles.text)}>
            <b>Communication & Support</b> - Trusted advisors with straightforward advice, excellent service, and fair, all-inclusive pricing.
          </div>
        </div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.starIcon)} src={StarIcon} alt="" />
          <div className={css(styles.text)}>
            <b>Seamless Service experience</b> - Our personalized process ensures you get the solution that is right for you from in-home consult to final
            walk-through.
          </div>
        </div>
        <div className={css(styles.textRow)}>
          <img className={css(styles.starIcon)} src={StarIcon} alt="" />
          <div className={css(styles.text)}>
            <b>Customer Satisfaction</b> - We consistently achieve some of the highest ratings in the industry. We set clear expectations and deliver on our
            promises. We're not happy until you're happy.
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    maxWidth: 500,
    padding: 16,
  },
  title: {
    fontFamily: Font.Header,
    fontWeight: 400,
    fontSize: 20,
    marginBottom: 13,
  },
  text: {
    fontFamily: Font.Normal,
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 18,
    marginTop: 2,
  },
  label: {
    fontSize: 14,
    color: Theme.labelColor,
    fontWeight: 600,
  },
  textarea: {
    boxSizing: 'border-box',
    backgroundColor: Theme.inputColor,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Theme.borderColor,
    borderStyle: 'solid',
    resize: 'none',
    height: 80,
    width: '100%',
    margin: 0,
    marginTop: 12,
    padding: 6,
    fontFamily: 'inherit',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    width: '100%',
  },
  pre: {
    fontFamily: 'inherit',
  },
  icon: {
    height: 22,
    width: 22,
    marginRight: 10,
  },
  starIcon: {
    height: 15.16,
    width: 16,
    marginRight: 10,
    marginTop: 4,
  },
  vanIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  vanIcon: {
    width: '100%',
    height: 'auto',
    maxWidth: 308,
    marginBottom: 24,
    marginTop: 12,
  },
  textRow: {
    display: 'flex',
    alignItems: 'flex-start',
  },
})
