import { css, StyleSheet } from 'aphrodite'
import { Font } from '../Styles'

interface IProps {
  title: string
  subtitle?: string
}

export default function Header(props: IProps) {
  const { title, subtitle } = props
  return (
    <div className={css(styles.header)}>
      <h1 className={css(styles.title)}>{title}</h1>
      {!!subtitle && <h2 className={css(styles.subtitle)}>{subtitle}</h2>}
    </div>
  )
}

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    padding: 0,
    margin: 0,
    textAlign: 'center',
    paddingTop: 10,
    marginBottom: 6,
    fontFamily: Font.Header,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: Font.Normal,
    fontWeight: 400,
    marginBottom: 18,
  },
})
