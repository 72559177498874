import { css, StyleSheet } from 'aphrodite'
import { useEffect, useMemo, useState } from 'react'
import { IUpdateLeadRequest } from '../Api'
import Button from '../components/Button'
import Header from '../components/Header'
import AddressInput from '../components/inputs/AddressInput'
import EmailInput from '../components/inputs/EmailInput'
import NameInput from '../components/inputs/NameInput'
import PhoneInput from '../components/inputs/PhoneInput'
import TextAreaInput from '../components/inputs/TextAreaInput'
import TextInput from '../components/inputs/TextInput'
import EmailIcon from '../icons/email.png'
import LocationIcon from '../icons/location.png'
import PhoneIcon from '../icons/phone.png'
import TickIcon from '../icons/tick.png'
import { useStateValue } from '../state/State'
import { Font, Theme } from '../Styles'
import { IContactInfo, InputId, Page } from '../Types'
import { formatOrgAddress, getIsContactInfoValid } from '../Utils'

const defaultContactInfo: IUpdateLeadRequest = {
  name: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  postalCode: '',
  state: '',
  country: 'US',
  smsOptIn: true,
  notes: '',
  trustedFormCertUrl: '',
  lat: undefined,
  lng: undefined,
  extraProperties: {},
}

export default function ContactInfoPage() {
  const [{ actions, /* embedded, */ theme, organization, lead, appointment, selectedTimeslot, isLoading }] = useStateValue()
  const [contactInfo, setContactInfo] = useState<IUpdateLeadRequest>(defaultContactInfo)

  useEffect(() => {
    console.log('add trustedform')
    const tf = document.createElement('script')
    tf.type = 'text/javascript'
    tf.async = true
    tf.src =
      ('https:' === document.location.protocol ? 'https' : 'http') +
      '://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=' +
      new Date().getTime() +
      Math.random()
    const scripts = document.getElementsByTagName('script')
    if (scripts.length < 1) {
      console.error('no scripts on the page!')
      return
    }
    if (scripts[0].src === tf.src) {
      console.log('already loaded tf script')
      return
    }

    ;(window as any).trustedFormCertUrlCallback = (url: string) => {
      console.log('received trustedform url', url)
      setContactInfo((contactInfo) => {
        return {
          ...contactInfo,
          trustedFormCertUrl: url,
        }
      })
    }

    scripts[0].parentNode!.insertBefore(tf, scripts[0])
    console.log('added tf script')
  }, [])

  useEffect(() => {
    const extraProperties: { [key: string]: any } = {}
    const uri = new URL(document.location.href);
    uri.searchParams?.forEach((v, k) => (extraProperties[k] = v))

    setContactInfo({
      name: lead?.name || '',
      email: lead?.email || '',
      phone: lead?.phone || '',
      address: lead?.address || '',
      city: lead?.city || '',
      postalCode: lead?.postalCode || '',
      state: lead?.state || '',
      country: 'US',
      smsOptIn: !!lead?.communicationPreferences?.SMS ? lead.communicationPreferences.SMS === 'OptedIn' : true,
      notes: lead?.notes || '',
      extraProperties
    })
  }, [lead])

  const isValid = useMemo(() => {
    return getIsContactInfoValid(contactInfo)
  }, [contactInfo])

  const isButtonDisabled = useMemo(() => {
    return !isValid || isLoading
  }, [isValid, isLoading])

  const orgAddress = useMemo(() => {
    if (!!organization) {
      return formatOrgAddress(organization)
    }
    return ''
  }, [organization])

  const infoChanged = useMemo(() => {
    const optedIn = lead?.communicationPreferences?.SMS === 'OptedIn'
    return (
      contactInfo?.name !== lead?.name ||
      contactInfo?.email !== lead?.email ||
      contactInfo?.phone !== lead?.phone ||
      contactInfo?.address !== lead?.address ||
      contactInfo?.city !== lead?.city ||
      contactInfo?.state !== lead?.state ||
      contactInfo?.postalCode !== lead?.postalCode ||
      contactInfo?.smsOptIn !== optedIn ||
      contactInfo?.notes !== lead?.notes
    )
  }, [contactInfo, lead])

  const buttonText = useMemo(() => {
    if (!!lead && (!!appointment || !!selectedTimeslot)) {
      return 'Save'
    }
    return 'Next'
  }, [lead, appointment, selectedTimeslot])

  // for now, never show side bar
  // const showOrgSidebar = useMemo(() => {
  //   return !embedded && !!organization
  // }, [embedded, organization])

  const showOrgSidebar = false

  const onAddLeadClicked = async () => {
    if (!!lead) {
      if (infoChanged) {
        actions.updateLead(contactInfo)
      } else {
        actions.goToPage(appointment || selectedTimeslot ? Page.AppointmentConfirmation : Page.Scheduler)
      }
    } else {
      actions.addLead(contactInfo)
    }
  }

  const onChange = (id: InputId, value: string) => {
    switch (id) {
      case InputId.Name:
        setContactInfo({ ...contactInfo, name: value })
        break
      case InputId.Phone:
        setContactInfo({ ...contactInfo, phone: value })
        break
      case InputId.Email:
        setContactInfo({ ...contactInfo, email: value })
        break
      case InputId.Address:
        setContactInfo({ ...contactInfo, address: value })
        break
      case InputId.City:
        setContactInfo({ ...contactInfo, city: value })
        break
      case InputId.Zip:
        setContactInfo({ ...contactInfo, postalCode: value })
        break
      case InputId.State:
        setContactInfo({ ...contactInfo, state: value })
        break
      case InputId.Country:
        setContactInfo({ ...contactInfo, country: value })
        break
      case InputId.Note:
        setContactInfo({ ...contactInfo, notes: value })
        break
    }
  }

  const onAutoCompleteAddress = (value: IContactInfo) => setContactInfo((prev) => ({ ...prev, ...value }))

  const onChangeSms = () => setContactInfo((prev) => ({ ...prev, smsOptIn: !contactInfo.smsOptIn }))

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        margin: 'auto',
        maxWidth: 750,
        padding: 16,
        '@media (max-width: 750px)': {
          maxWidth: 500,
        },
      },
      embeddedContainer: {
        maxWidth: 500,
      },
      card: {
        display: 'flex',
        boxShadow: Theme.boxShadow,
        borderRadius: 8,
        backgroundColor: Theme.cardColor,
        '@media (max-width: 750px)': {
          boxShadow: 'none',
          backgroundColor: 'none',
        },
      },
      sidebar: {
        width: 250,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        backgroundColor: theme.primaryColor,
        '@media (max-width: 750px)': {
          display: 'none',
        },
      },
      sidebarContent: {
        color: '#fff',
        padding: 16,
      },
      sidebarTitle: {
        fontSize: 22,
        fontWeight: 400,
        marginBottom: 6,
        fontFamily: Font.Header,
      },
      sidebarText: {
        fontSize: 14,
        fontWeight: 500,
      },
      sidebarInfo: {
        alignItems: 'flex-start',
        display: 'flex',
        marginTop: 8,
      },
      sidebarIcon: {
        marginTop: 4,
        marginRight: 6,
      },
      sidebarLink: {
        color: '#fff',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      formContainer: {
        flex: 1,
        maxWidth: 500,
      },
      form: {
        margin: 24,
        '@media (max-width: 750px)': {
          margin: 0,
        },
      },
      row: {
        display: 'flex',
        '@media (max-width: 500px)': {
          flexDirection: 'column',
        },
      },
      gap: {
        width: 12,
      },
      buttonContainer: {
        paddingTop: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      alwaysRow: {
        display: 'flex',
        marginLeft: 12,
        '@media (max-width: 500px)': {
          marginLeft: 0,
        },
      },
      checkbox: {
        height: 20,
        width: 20,
        boxSizing: 'border-box',
        backgroundColor: '#1C294A',
        borderRadius: 5,
        borderStyle: 'solid',
        borderColor: '#1C294A',
        borderWidth: 1,
        cursor: 'pointer',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      checkboxUnchecked: {
        backgroundColor: 'transparent',
      },
      checkboxRow: {
        alignItems: 'flex-start',
        display: 'flex',
        marginTop: 6,
        marginBottom: 6,
      },
      checkboxText: {
        flex: 1,
        // marginLeft: 10,
        lineHeight: '12pt',
        fontFamily: Font.Normal,
        fontSize: 12,
      },
      externalLink: {
        color: 'black',
        // textDecoration: 'none',
      }
    })
  }, [theme])

  return (
    <div className={css([styles.container, !showOrgSidebar && styles.embeddedContainer])}>
      <Header title={'Let Us Know Who You Are'} />
      <div className={css([styles.card])}>
        {showOrgSidebar && (
          <div className={css(styles.sidebar)}>
            <div className={css(styles.sidebarContent)}>
              {!!organization?.name && <div className={css(styles.sidebarTitle)}>{organization.name}</div>}
              {!!organization?.description && <div className={css(styles.sidebarText)}>{organization.description}</div>}
              {!!organization?.email && (
                <div className={css(styles.sidebarInfo)}>
                  <img className={css(styles.sidebarIcon)} src={EmailIcon} alt="Email" />
                  <a className={css(styles.sidebarLink)} href={`mailto:${organization.email}`}>
                    {organization.email}
                  </a>
                </div>
              )}
              {!!organization?.phone && (
                <div className={css(styles.sidebarInfo)}>
                  <img className={css(styles.sidebarIcon)} src={PhoneIcon} alt="Phone" />
                  <a className={css(styles.sidebarLink)} href={`tel:${organization.phone}`}>
                    {organization.phone}
                  </a>
                </div>
              )}
              {!!orgAddress?.length && (
                <div className={css(styles.sidebarInfo)}>
                  <img className={css(styles.sidebarIcon)} src={LocationIcon} alt="Location" />
                  <a
                    className={css(styles.sidebarLink)}
                    href={`https://www.google.com/maps/search/?api=1&query=${orgAddress}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {orgAddress}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={css(styles.formContainer)}>
          <div className={css(styles.form)}>
            <NameInput onChange={onChange} value={contactInfo.name} />
            <div className={css(styles.row)}>
              <PhoneInput onChange={onChange} value={contactInfo.phone} />
              <div className={css(styles.gap)} />
              <EmailInput onChange={onChange} value={contactInfo.email} />
            </div>
            <AddressInput onChange={onChange} onAutoComplete={onAutoCompleteAddress} value={contactInfo.address} />
            <div className={css(styles.row)}>
              <TextInput id={InputId.City} label="City *" placeholder="Write here..." onChange={onChange} value={contactInfo.city} />
            </div>
            <div className={css(styles.row)}>
              <TextInput id={InputId.State} label="State / Province *" placeholder="Write here..." onChange={onChange} value={contactInfo.state} />
              <div className={css(styles.gap)} />
              <TextInput id={InputId.Zip} label="ZIP / Postal Code *" placeholder="Write here..." onChange={onChange} value={contactInfo.postalCode} />
            </div>
            <TextAreaInput id={InputId.Note} label="Project Detail" placeholder="Tell us about your project..." onChange={onChange} value={contactInfo.notes} />
            <div className={css(styles.checkboxRow)}>
              {/* <button className={css([styles.checkbox, !contactInfo?.smsOptIn && styles.checkboxUnchecked])} type="button" onClick={onChangeSms}>
                <img src={TickIcon} alt="" />
              </button> */}
              <div className={css(styles.checkboxText)}>
                By clicking next or submit you agree to receive phone, email, or text communication from Floor Coverings International, franchisees, and 3rd-party service providers per our
                &nbsp;<a className={css(styles.externalLink)} href="https://floorcoveringsinternational.com/terms/" target='_blank'>Terms & Conditions</a>&nbsp; and 
                &nbsp;<a className={css(styles.externalLink)} href="https://floorcoveringsinternational.com/privacy-policy/" target='_blank'>Privacy Policy</a>&nbsp;. 
                Message/data rates may apply. 
                Consent is not a condition of purchase.
              </div>
            </div>
            <div className={css(styles.buttonContainer)}>
              <Button id="add-lead" text={buttonText} onClick={onAddLeadClicked} disabled={isButtonDisabled} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
