import { useCallback, useEffect, useMemo, useRef } from 'react'
import { IContactInfo, InputId } from '../../Types'
import Input, { IBaseInputProps } from './Input'

interface IProps extends IBaseInputProps {
  onAutoComplete(contactInfo: Partial<IContactInfo>): void
}

export default function AddressInput(props: IProps) {
  const { onChange, onAutoComplete, value } = props

  const ref = useRef<HTMLInputElement>(null)

  const onPlaceSelected = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const newContactInfo: Partial<IContactInfo> = { address: place.name }
      if (!!ref.current && !!place?.name) {
        ref.current.value = place.name
      }

      if (!!place?.geometry?.location) {
        newContactInfo.lat = place.geometry.location.lat().toString()
        newContactInfo.lng = place.geometry.location.lng().toString()
      } else {
        newContactInfo.lat = undefined;
        newContactInfo.lng = undefined;
      }

      if (!!place?.address_components?.length) {
        place.address_components.forEach((component) => {
          if (!!component?.types?.length) {
            component.types.forEach((type) => {
              if (type === 'locality') {
                newContactInfo.city = component.short_name
              } else if (type === 'administrative_area_level_1') {
                newContactInfo.state = component.short_name
              } else if (type === 'country') {
                newContactInfo.country = component.short_name
              } else if (type === 'postal_code') {
                newContactInfo.postalCode = component.short_name
              }
            })
          }
        })
      }
      onAutoComplete(newContactInfo)
    },
    [onAutoComplete]
  )

  const initPlaceAPI = useCallback(() => {
    if (!!ref?.current) {
      const options: google.maps.places.AutocompleteOptions = {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'name', 'geometry/location'],
        types: ['address'],
      }
      const autocomplete = new window.google.maps.places.Autocomplete(ref.current, options)
      new (window.google.maps.event.addListener as any)(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace()
        onPlaceSelected(place)
      })
    }
  }, [onPlaceSelected])

  useEffect(() => {
    initPlaceAPI()
  }, [initPlaceAPI])

  const hasError = useMemo(() => {
    return !value
  }, [value])

  return <Input id={InputId.Address} label="Address *" placeholder="Write here..." ref={ref} hasError={hasError} onChange={onChange} initialValue={value} />
}
