import { css, StyleSheet } from 'aphrodite'
import { useMemo } from 'react'
import Button from '../components/Button'
import Header from '../components/Header'
import { useStateValue } from '../state/State'
import { Theme } from '../Styles'
import { Page } from '../Types'

export default function AppointmentCancelledPage() {
  const [{ actions, lead }] = useStateValue()

  const onClickSchedule = () => actions.goToPage(Page.Scheduler)

  const styles = useMemo(() => {
    return StyleSheet.create({
      card: {
        display: 'flex',
        boxShadow: Theme.boxShadow,
        borderRadius: 8,
        backgroundColor: Theme.cardColor,
        width: '100%',
        marginBottom: 16,
      },
      row: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 8,
        borderBottomColor: Theme.borderColor,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        paddingBottom: 4,
      },
      label: {
        fontSize: 14,
        color: Theme.labelColor,
        fontWeight: 600,
      },
      content: {
        flex: 1,
        padding: 16,
      },
      container: {
        margin: 'auto',
        maxWidth: 500,
        padding: 8,
      },
      text: {
        textAlign: 'center',
        marginBottom: 12,
      },
      buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 16,
      },
    })
  }, [])

  return (
    <div className={css(styles.container)}>
      <Header title="Appointment Cancelled" />
      <div className={css(styles.card)}>
        <div className={css(styles.content)}>
          <div className={css(styles.row)}>
            <div className={css(styles.label)}>{'Name'}</div>
            <div>{lead?.name || ''}</div>
          </div>
          <div className={css(styles.row)}>
            <div className={css(styles.label)}>{'Email'}</div>
            <div>{lead?.email || ''}</div>
          </div>
          <div className={css(styles.row)}>
            <div className={css(styles.label)}>{'Phone'}</div>
            <div>{lead?.phone || ''}</div>
          </div>
          <div className={css(styles.buttonContainer)}>
            <Button id="schedule-new-appointment" text="Schedule New Appointment" onClick={onClickSchedule} />
          </div>
        </div>
      </div>
    </div>
  )
}
