import { css, StyleSheet } from 'aphrodite'
import ErrorIcon from '../../icons/error.png'
import { Font, Theme } from '../../Styles'

export interface IProps {
  id: string
  text: string
  showError?: boolean
}

export default function Label(props: IProps) {
  const { id, text, showError } = props

  return (
    <div className={css(styles.label)}>
      <label htmlFor={id}>{`${text}`}</label>
      {showError && <img className={css(styles.icon)} src={ErrorIcon} alt="Invalid" />}
    </div>
  )
}

const styles = StyleSheet.create({
  label: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 6,
    fontSize: 18,
    color: Theme.labelColor,
    fontWeight: 400,
    fontFamily: Font.Header,
  },
  icon: {
    marginLeft: 4,
  },
})
