import { css, StyleSheet } from 'aphrodite'
import { useCallback, useEffect } from 'react'
import ErrorModal from './components/ErrorModal'
import AppointmentCancelledPage from './pages/AppointmentCancelledPage'
import AppointmentConfirmationPage from './pages/AppointmentConfirmationPage'
import AppointmentScheduledPage from './pages/AppointmentScheduledPage'
import ContactInfoPage from './pages/ContactInfoPage'
import SchedulerPage from './pages/SchedulerPage'
import { useStateValue } from './state/State'
import { Page } from './Types'

export default function PageContainer() {
  const [{ actions, embedded, page, session, showUnavailable }, dispatch] = useStateValue()

  useEffect(() => {
    actions.setDispatch(dispatch)
    actions.getSlots(showUnavailable)
  }, [actions, showUnavailable, dispatch])

  // Reset scroll position when step changes
  useEffect(() => window.scrollTo(0, 0), [page])

  const renderContent = useCallback(() => {
    switch (page) {
      case Page.ContactInfo:
        return <ContactInfoPage />
      case Page.Scheduler:
        return <SchedulerPage />
      case Page.AppointmentConfirmation:
        return <AppointmentConfirmationPage />
      case Page.AppointmentScheduled:
        return <AppointmentScheduledPage />
      case Page.AppointmentCancelled:
        return <AppointmentCancelledPage />
    }
  }, [page])

  return (
    <div className={css(styles.container)}>
      {!embedded && !!session?.logoUrl && (
        <div className={css(styles.logoContainer)}>
          <img className={css(styles.logo)} src={session.logoUrl} alt="" />
        </div>
      )}
      <div className={css(styles.content)}>{renderContent()}</div>
      <ErrorModal />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
  },
  content: {
    width: '100%',
  },
  logoContainer: {
    margin: 'auto',
    width: '100%',
    backgroundColor: '#fff',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: '#BBBDC0',
    borderBottomWidth: 0.5,
    borderBottomStyle: 'solid',
  },
  logo: {
    display: 'block',
    maxHeight: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
