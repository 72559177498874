import { css, StyleSheet } from 'aphrodite'
import { useMemo } from 'react'
import { NamedSlot } from '../Api'
import { useStateValue } from '../state/State'
import { Font, Theme } from '../Styles'
import { formatDateTimeShort } from '../Utils'

interface IProps {
  onClick(slot: NamedSlot): void
  slot: NamedSlot
}

export default function QuickPickSlot(props: IProps) {
  const { slot } = props
  const [{ theme }] = useStateValue()

  const date = useMemo(() => {
    if (!!slot?.start) {
      return new Date(slot?.start)
    }
    return undefined
  }, [slot])

  const onClick = () => props.onClick(slot)

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        alignItems: 'center',
        backgroundColor: Theme.cardColor,
        borderRadius: 8,
        borderWidth: 0,
        marginBottom: 12,
        boxShadow: Theme.boxShadow,
        padding: 0,
        cursor: 'pointer',
        marginLeft: 6,
        marginRight: 6,
        width: 200,
        '@media (max-width: 500px)': {
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
        },
      },
      indicator: {
        width: '100%',
        height: 5,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: theme.primaryColor,
      },
      textContainer: {
        textAlign: 'center',
        width: '100%',
      },
      nameText: {
        fontFamily: Font.Header,
        color: '#1C294A',
        fontWeight: 700,
        fontSize: 16,
      },
      dateText: {
        color: Theme.labelColor,
        fontFamily: Font.Header,
        fontWeight: 400,
        fontSize: 16,
      },
    })
  }, [theme])

  return (
    <button className={css(styles.container)} onClick={onClick}>
      <div className={css(styles.indicator)} />
      <div className={css(styles.textContainer)}>
        <div className={css(styles.nameText)}>{slot?.name || ''}</div>
        <div className={css(styles.dateText)}>{formatDateTimeShort(date)}</div>
      </div>
    </button>
  )
}
