import { css, StyleSheet } from 'aphrodite'
import { useMemo } from 'react'
import { Theme } from '../Styles'

interface IProps {
  danger?: boolean
  disabled?: boolean
  id: string
  onClick(): void
  text: string
}

export default function Button(props: IProps) {
  const { danger, disabled, id, onClick, text } = props

  const styles = useMemo(() => {
    return StyleSheet.create({
      button: {
        height: 48,
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: 1,
        color: danger ? Theme.dangerColor : '#fff',
        fontSize: 20,
        fontWeight: 400,
        maxWidth: 500,
        paddingLeft: 16,
        paddingRight: 16,
        cursor: 'pointer',
        backgroundColor: danger ? '#fff' : '#80B549',
        borderColor: danger ? Theme.dangerColor : '#80B549',
        width: '100%',
      },
      disabled: {
        backgroundColor: Theme.disabledColor,
        borderColor: Theme.disabledColor,
        cursor: 'unset',
      },
    })
  }, [danger])

  return (
    <button className={`${id}-button ${css(styles.button, disabled && styles.disabled)}`} type="button" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  )
}
