import { css, StyleSheet } from 'aphrodite'

export default function Loading() {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.spinner)} />
    </div>
  )
}

const spin = {
  '0%': {
    transform: 'translate3d(-50%, -50%, 0) rotate(0deg)',
  },
  '100%': {
    transform: 'translate3d(-50%, -50%, 0) rotate(360deg)',
  },
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100vh',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    animationName: spin,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    height: 48,
    width: 48,
    borderWidth: 8,
    borderStyle: 'solid',
    borderRadius: '50%',
    borderColor: '#EEEEEE',
    borderTopColor: '#212121',
  },
})
