import { css, StyleSheet } from 'aphrodite'
import { useMemo } from 'react'
import ErrorIcon from '../icons/svg/ErrorIcon'
import { parseError } from '../Utils'

interface IProps {
  error?: any
}

export default function Error(props: IProps) {
  const { error } = props

  const message = useMemo(() => {
    return parseError(error)
  }, [error])

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.content)}>
        <ErrorIcon className={css(styles.icon)} />
        <div>{message}</div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    height: 64,
    width: 64,
  },
})
