import { css, StyleSheet } from 'aphrodite'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import PageContainer from '../PageContainer'
import { Actions } from '../state/Actions'
import { ISchedulerState, StateProvider } from '../state/State'
import { Font, Theme } from '../Styles'
import { SearchParams } from '../Types'

export default function ZipRoute() {
  const [searchParams] = useSearchParams()
  const [zip, setZip] = useState('')
  const [initialState, setInitialState] = useState<ISchedulerState>()
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setZip(event.target.value)

  const onSubmit = async () => {
    setLoading(true)
    const embedded = searchParams.get(SearchParams.Embedded)
    const state = await Actions.initSessionForZip(zip, embedded)
      .catch((reason) => {
        setLoading(false)

        if (reason.status === 400) {
          try {
            const json = JSON.parse(reason.response);
            if ("message" in json) {
              setError(json.message);
              return;
            }

          } catch {
            // unexpected response format
            console.error(reason.response);
          }
        }

        setError(
          "We're sorry, the zip code entered is not in our serviceable area at this time.  Please give us a call at 1-800-342-5324 and we will be happy to try to match you with the closest location."
        )
      })

    if (state) {
      setInitialState(state);
    }
  }

  const embedded = useMemo(() => {
    const embeddedString = searchParams.get(SearchParams.Embedded)
    return embeddedString === 'false' ? false : true
  }, [searchParams])

  const hasError = useMemo(() => {
    if (!zip) {
      return true
    }
    const us = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
    const ca = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i)
    return !us.test(zip) && !ca.test(zip)
  }, [zip])

  const buttonDisabled = useMemo(() => {
    return hasError || loading
  }, [hasError, loading])

  if (!initialState) {
    return (
      <div>
        {!embedded && (
          <div className={css(styles.logoContainer)}>
            <img className={css(styles.logo)} src={'https://links.fci.cloud/logo.png'} alt="" />
          </div>
        )}
        <div className={css(styles.container)}>
          <div className={css(styles.header)}>
            <h1 className={css(styles.title)}>{'Welcome to Floor Coverings International!'}</h1>
            <h2 className={css(styles.subtitle)}>{'To schedule your Free In-Home Consultation, please enter your zip or postal code'}</h2>
          </div>
          <div className={css(styles.form)}>
            <input className={`submit-zip-field ${css(styles.input)}`} type={'text'} value={zip} placeholder="Enter Zip or Postal Code" onChange={onChange} />
            {!!error && !loading && <div className={css(styles.error)}>{error}</div>}
            <button
              className={`submit-zip-button ${css(styles.button, buttonDisabled && styles.disabled)}`}
              type="submit"
              onClick={onSubmit}
              disabled={buttonDisabled}
            >
              {'Submit'}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <StateProvider initialState={initialState}>
      <PageContainer />
    </StateProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    maxWidth: 350,
    padding: 16,
  },
  form: {
    width: '100%',
    flex: 1,
  },
  input: {
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#FBFAF8',
    borderRadius: 8,
    border: '1px solid #BBBDC0',
    height: 48,
    marginBottom: 14,
    marginTop: 14,
    fontFamily: Font.Normal,
    fontSize: 14,
    paddingLeft: 23,
    paddingRight: 23,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    padding: 0,
    margin: 0,
    textAlign: 'center',
    paddingTop: 10,
    marginBottom: 6,
    fontFamily: Font.Header,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: Font.Normal,
    fontWeight: 400,
    marginBottom: 18,
  },
  button: {
    height: 48,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#fff',
    fontSize: 20,
    fontWeight: 400,
    maxWidth: 500,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'pointer',
    backgroundColor: '#80B549',
    borderColor: '#80B549',
    width: '100%',
  },
  disabled: {
    backgroundColor: Theme.disabledColor,
    borderColor: Theme.disabledColor,
    cursor: 'unset',
  },
  logoContainer: {
    margin: 'auto',
    width: '100%',
    backgroundColor: '#fff',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottomColor: '#BBBDC0',
    borderBottomWidth: 0.5,
    borderBottomStyle: 'solid',
  },
  logo: {
    display: 'block',
    maxHeight: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  error: {
    textAlign: 'center',
    color: Theme.dangerColor,
    fontSize: 14,
    marginBottom: 14,
  },
})
