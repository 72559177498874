import { useMemo } from 'react'
import Input, { IInputProps } from './Input'

export default function TextInput(props: IInputProps) {
  const { value } = props

  const hasError = useMemo(() => {
    return !value?.length
  }, [value])

  return <Input {...props} hasError={hasError} />
}
