import { createContext, useContext, useReducer } from 'react'
import { Appointment, Lead, OrganizationResp, SessionConfig, SlotsResp, TimeSlot } from '../Api'
import { ITheme, Page } from '../Types'
import { Actions } from './Actions'

export interface ISchedulerState {
  actions: Actions
  page: Page
  session: SessionConfig
  theme: ITheme
  // Optional
  appointment?: Appointment
  organization?: OrganizationResp
  embedded?: boolean
  error?: string
  isLoading?: boolean
  lead?: Lead
  selectedTimeslot?: TimeSlot
  slots?: SlotsResp
  note?: string
  showUnavailable: boolean;
}

export type Action =
  | {
      type: 'addLead'
      payload: Lead
    }
  | {
      type: 'updateLead'
      payload: Lead
    }
  | {
      type: 'selectTimeslot'
      payload: TimeSlot
    }
  | {
      type: 'addAppointment'
      payload: Appointment
    }
  | {
      type: 'deleteAppointment'
    }
  | {
      type: 'goToPage'
      payload: Page
    }
  | {
      type: 'loading'
    }
  | {
      type: 'error'
      payload: string
    }
  | {
      type: 'addNote'
      payload: string
    }
  | {
      type: 'setSlots'
      payload: SlotsResp
    }
    | {
      type: 'showUnavailable'
    }
    | {
      type: 'hideUnavailable'
    }

export const StateContext = createContext({} as [ISchedulerState, React.Dispatch<Action>])

const reducer = (state: ISchedulerState, action: Action): ISchedulerState => {
  switch (action.type) {
    case 'setSlots': {
      return {
        ...state,
        slots: action.payload,
      }
    }
    case 'addLead':
      return {
        ...state,
        lead: action.payload,
        page: Page.Scheduler,
        isLoading: false,
      }
    case 'updateLead':
      return {
        ...state,
        lead: action.payload,
        page: (state.appointment || state.selectedTimeslot) ? Page.AppointmentConfirmation : Page.Scheduler,
        isLoading: false,
      }
    case 'selectTimeslot':
      return {
        ...state,
        selectedTimeslot: action.payload,
        page: Page.AppointmentConfirmation,
      }
    case 'addAppointment':
      return {
        ...state,
        appointment: action.payload,
        page: Page.AppointmentScheduled,
        isLoading: false,
      }
    case 'deleteAppointment':
      return {
        ...state,
        page: Page.AppointmentCancelled,
        appointment: undefined,
        isLoading: false,
      }
    case 'addNote': {
      return {
        ...state,
        isLoading: false,
        note: action.payload,
      }
    }
    case 'goToPage': {
      return {
        ...state,
        page: action.payload,
      }
    }
    case 'loading': {
      return {
        ...state,
        isLoading: true,
      }
    }
    case 'error': {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    }
    case 'showUnavailable': {
      return {
        ...state,
        showUnavailable: true,
        slots: undefined,
      }
    }
    case 'hideUnavailable': {
      return {
        ...state,
        showUnavailable: false,
        slots: undefined,
      }
    }    
    default:
      return state
  }
}

export const StateProvider = ({ children, initialState }: { children: React.ReactNode; initialState: ISchedulerState }) => {
  return <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>
}

export const useStateValue = () => useContext(StateContext)
