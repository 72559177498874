import { TimeSlot } from './Api'

export type AuthToken = {
  access_token: string
  expires_in: number
  scope: string
  token_type: string
}

export interface ITheme {
  primaryColor: string
  secondaryColor: string
}

export enum InputId {
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
  Address = 'address',
  City = 'city',
  Zip = 'zip',
  State = 'state',
  Country = 'country',
  Note = 'note',
}

export interface IContactInfo {
  name: string
  phone: string
  email: string
  address: string
  city: string
  state: string
  postalCode: string
  country: string
  note: string
  trustedFormCertUrl?: string
  lat?: string
  lng?: string
  extraProperties?: {[key: string]: any}
}

export enum SearchParams {
  Auth = 'auth',
  Embedded = 'embedded',
  LaunchCode = 'launchCode',
  Zip = 'zip',
}

export enum Page {
  AppointmentCancelled,
  AppointmentConfirmation,
  AppointmentScheduled,
  ContactInfo,
  Scheduler,
}

export interface ISectionSettings {
  fallbackInstructions?: string
  isAllowed?: boolean
  isVisible?: boolean
  label?: string
}

export enum TimePickerWindow {
  All = 'All',
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}

export interface IDayPickerConfig {
  disabledDays: Date[]
  selectedDate: Date
  fromDate: Date
  slots: TimeSlot[]
  toDate: Date
  window: TimePickerWindow
}

export interface IWindowContext {
  leadTypeId?: string
  embedded?: string
}
