export const Theme = {
  dangerColor: '#f54646',
  borderColor: '#d9dade',
  backgroundColor: '#ffffff',
  cardColor: '#fff',
  inputColor: '#FBFAF8',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  disabledColor: '#e2e2e2',
  infoColor: '#FFF9C4',
  warningColor: '#FFE0B2',
  labelColor: '#363f4e',
}

export enum Font {
  Header = "'Pragati Narrow', serif",
  Normal = "'Libre Baskerville', serif",
}
