import { css, StyleSheet } from 'aphrodite'
import { Theme } from '../Styles'

interface IProps {
  children: React.ReactNode
  showModal: boolean
}

export default function Modal(props: IProps) {
  const { children, showModal } = props

  if (!showModal) {
    return null
  }

  return (
    <div className={css(styles.background)}>
      <div className={css(styles.container)}>
        <div className={css(styles.modal)}>{children}</div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  background: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  modal: {
    margin: 'auto',
    backgroundColor: '#fff',
    width: 250,
    borderColor: Theme.borderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 16,
    borderRadius: 8,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})
