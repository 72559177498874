import { css, StyleSheet } from 'aphrodite'
import { useEffect, useMemo, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { TimeSlot } from '../Api'
import { Font, Theme } from '../Styles'
import { IDayPickerConfig, TimePickerWindow } from '../Types'
import { formatDate, formatTime, getDayPickerConfig, getIsSameDay } from '../Utils'

interface IProps {
  onSelect(slot: TimeSlot): void
  selectedSlot?: TimeSlot
  slots: TimeSlot[]
  allowUnavailableSlots: boolean
  showUnavailable: boolean
  onToggleUnavailable: () => void
}

export default function TimeslotPicker(props: IProps) {
  const { onSelect, slots, allowUnavailableSlots, showUnavailable, onToggleUnavailable } = props

  const [selectedDate, setSelectedDate] = useState<Date>()
  const [selectedMonth, setSelectedMonth] = useState<Date>()
  const [dayPickerConfig, setDayPickerConfig] = useState<IDayPickerConfig>()

  useEffect(() => {
    const config = getDayPickerConfig(slots, TimePickerWindow.All)
    setDayPickerConfig(config)
    setSelectedDate(config.selectedDate)
    setSelectedMonth(config.selectedDate)
  }, [slots])

  const times = useMemo(() => {
    return dayPickerConfig?.slots?.filter((timeSlot) => {
      const isSameDay = getIsSameDay(timeSlot.start, selectedDate)
      return isSameDay
    })
  }, [selectedDate, dayPickerConfig])

  const onSetSelectedWindow = (value: TimePickerWindow) => () => {
    const config = getDayPickerConfig(slots, value, selectedDate)
    setDayPickerConfig(config)
    setSelectedDate(config.selectedDate)
    setSelectedMonth(config.selectedDate)
  }

  const onSetSelectedTimeSlot = (value: TimeSlot) => () => onSelect(value)

  const onSelectDate = (value: Date | undefined) => {
    if (!!value) {
      setSelectedDate(value)
    }
  }

  const onMonthChange = (value: Date) => setSelectedMonth(value)

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        display: 'flex',
        width: '100%',
        boxShadow: Theme.boxShadow,
        borderRadius: 8,
        backgroundColor: Theme.cardColor,
        flexDirection: 'column',
      },
      pickers: {
        display: 'flex',
        '@media (max-width: 750px)': {
          alignItems: 'center',
          flexDirection: 'column',
        },
      },
      dayPicker: {
        marginTop: 8,
        fontFamily: Font.Header,
        fontSize: 16,
      },
      timePicker: {
        flex: 1,
        padding: 12,
        paddingTop: 8,
      },
      dateText: {
        fontSize: 20,
        marginBottom: 12,
        fontWeight: 400,
        textAlign: 'center',
        fontFamily: Font.Header,
      },
      windows: {
        display: 'flex',
        borderColor: '#1C294A',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        margin: 12,
      },
      window: {
        flex: 1,
        padding: 8,
        cursor: 'pointer',
        textAlign: 'center',
        fontFamily: Font.Header,
        fontSize: 16,
        fontWeight: 400,
        borderStyle: 'solid',
        borderColor: '#1C294A',
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
      },
      windowLeft: {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderRightWidth: 1,
      },
      windowCenter: {
        borderRightWidth: 1,
      },
      windowRight: {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
      selectedWindow: {
        color: '#fff',
        backgroundColor: '#1C294A',
      },
      times: {
        justifyContent: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
      },
      time: {
        fontFamily: Font.Header,
        fontSize: 16,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: Theme.borderColor,
        borderWidth: 1,
        borderStyle: 'solid',
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 8,
        cursor: 'pointer',
        marginBottom: 8,
        marginRight: 8,
        textAlign: 'center',
        width: 90,
      },
      datePickerSelected: {
        color: '#fff',
      },
      unavailableDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '24px',
      },
      unavailableButton: {
        fontSize: 12,
        color: '#fff',
        backgroundColor: '#1C294A',
        borderRadius: 8,
        cursor: 'pointer',
        padding: 8,
        marginTop: 12,
      },
    })
  }, [])

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.windows)}>
        <div
          className={css(styles.window, styles.windowLeft, dayPickerConfig?.window === TimePickerWindow.All && styles.selectedWindow)}
          onClick={onSetSelectedWindow(TimePickerWindow.All)}
        >
          {TimePickerWindow.All}
        </div>
        <div
          className={css(styles.window, styles.windowCenter, dayPickerConfig?.window === TimePickerWindow.Morning && styles.selectedWindow)}
          onClick={onSetSelectedWindow(TimePickerWindow.Morning)}
        >
          {TimePickerWindow.Morning}
        </div>
        <div
          className={css(styles.window, styles.windowCenter, dayPickerConfig?.window === TimePickerWindow.Afternoon && styles.selectedWindow)}
          onClick={onSetSelectedWindow(TimePickerWindow.Afternoon)}
        >
          {TimePickerWindow.Afternoon}
        </div>
        <div
          className={css(styles.window, styles.windowRight, dayPickerConfig?.window === TimePickerWindow.Evening && styles.selectedWindow)}
          onClick={onSetSelectedWindow(TimePickerWindow.Evening)}
        >
          {TimePickerWindow.Evening}
        </div>
      </div>
      <div className={css(styles.pickers)}>
        <DayPicker
          className={css(styles.dayPicker)}
          mode="single"
          selected={selectedDate}
          onSelect={onSelectDate}
          fromDate={dayPickerConfig?.fromDate}
          toDate={dayPickerConfig?.toDate}
          disabled={dayPickerConfig?.disabledDays}
          modifiersClassNames={{ selected: css(styles.selectedWindow) }}
          month={selectedMonth}
          onMonthChange={onMonthChange}
        />
        <div>
          <div className={css(styles.timePicker)}>
            <div className={css(styles.dateText)}>{formatDate(selectedDate, true)}</div>
            <div className={css(styles.times)}>
              {!times?.length ? (
                <div></div>
              ) : (
                times?.map((time, i) => {
                  return (
                    <div key={`slot:${i}`} className={css(styles.time)} onClick={onSetSelectedTimeSlot(time)}>
                      <span>{formatTime(time?.start)}</span>
                    </div>
                  )
                })
              )}
            </div>
          </div>

          {allowUnavailableSlots && !showUnavailable && ( 
            <div className={css(styles.unavailableDiv)}>
              <button onClick={onToggleUnavailable} className={css(styles.unavailableButton)}>
                Don't see a time that works for you? <br />
                <span style={{ fontSize: '12pt', fontWeight: '600' }}>View More Appointments</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
