import parsePhoneNumber from 'libphonenumber-js'
import { useMemo } from 'react'
import { InputId } from '../../Types'
import Input, { IBaseInputProps } from './Input'

export default function PhoneInput(props: IBaseInputProps) {
  const { onChange, value } = props

  const hasError = useMemo(() => {
    const parsedPhone = parsePhoneNumber(value || '', 'US')
    return !parsedPhone?.isValid()
  }, [value])

  const onBlur = () => {
    const parsedPhone = parsePhoneNumber(value || '', 'US')
    if (parsedPhone?.isValid()) {
      const formattedPhone = parsedPhone.formatNational()
      onChange?.(InputId.Phone, formattedPhone)
    }
  }

  return <Input id={InputId.Phone} label="Phone Number *" placeholder="123-456-789" onChange={onChange} onBlur={onBlur} value={value} hasError={hasError} />
}
