import { css, StyleSheet } from 'aphrodite'
import ErrorIcon from '../icons/svg/ErrorIcon'
import { useStateValue } from '../state/State'
import Button from './Button'
import Modal from './Modal'

export default function ErrorModal() {
  const [{ actions, error }] = useStateValue()

  const clearError = () => actions.clearError()

  return (
    <Modal showModal={!!error}>
      <ErrorIcon className={css(styles.icon)} />
      <div className={css(styles.text)}>{error}</div>
      <div className={css(styles.buttonContainer)}>
        <Button id="error-modal-clear" text="Ok" onClick={clearError} />
      </div>
    </Modal>
  )
}

const styles = StyleSheet.create({
  icon: {
    height: 64,
    width: 64,
    display: 'block',
    margin: 'auto',
    marginBottom: 4,
  },
  text: {
    textAlign: 'center',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
})
