import { useMemo } from 'react'
import { InputId } from '../../Types'
import { validateNameRegex } from '../../Utils'
import Input, { IBaseInputProps } from './Input'

export default function NameInput(props: IBaseInputProps) {
  const { onChange, value } = props

  const hasError = useMemo(() => {
    if (!value) {
      return true
    }
    return !validateNameRegex.test(value)
  }, [value])

  return <Input id={InputId.Name} label="Name *" placeholder="Write here..." onChange={onChange} value={value} hasError={hasError} />
}
