import { css, StyleSheet } from 'aphrodite'
import { forwardRef, useMemo, useState } from 'react'
import { Font, Theme } from '../../Styles'
import { InputId } from '../../Types'
import Label from './Label'

export interface IBaseInputProps {
  onChange?(id: InputId, value: string): void
  value?: string
}

export interface IInputProps extends IBaseInputProps {
  hasError?: boolean
  id: InputId
  initialValue?: string
  label?: string
  onBlur?(): void
  placeholder?: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const { hasError, id, initialValue, label, placeholder, value } = props

  const [hasBlurred, setHasBlurred] = useState(false)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => props.onChange?.(id, event.target.value)

  const onBlur = () => {
    props.onBlur?.()
    setHasBlurred(true)
  }

  const showError = useMemo(() => {
    if (!hasBlurred) {
      return false
    }
    return hasError
  }, [hasError, hasBlurred])

  return (
    <div className={css(styles.container)}>
      {!!label && <Label id={id} text={label} showError={showError} />}
      <input
        className={`${id}-field ${css(styles.input)}`}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={initialValue || placeholder || ''}
        ref={ref}
        type={id === InputId.Phone ? 'tel' : 'text'}
        value={value}
      />
    </div>
  )
})

export default Input

const borderRadius = 8

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 6,
    fontSize: 18,
    color: Theme.labelColor,
    fontWeight: 400,
    fontFamily: Font.Header,
  },
  input: {
    marginBottom: 8,
    backgroundColor: Theme.inputColor,
    borderRadius,
    borderWidth: 1,
    borderColor: Theme.borderColor,
    borderStyle: 'solid',
    paddingLeft: 12,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: Font.Normal,
    fontSize: 14,
  },
  icon: {
    marginLeft: 4,
  },
})
