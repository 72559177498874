import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Error from '../components/Error'
import Loading from '../components/Loading'
import PageContainer from '../PageContainer'
import { Actions } from '../state/Actions'
import { ISchedulerState, StateProvider } from '../state/State'
import { SearchParams } from '../Types'

export default function LeadRoute() {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [initialState, setInitialState] = useState<ISchedulerState>()
  const [error, setError] = useState<any>()

  useEffect(() => {
    const { auth } = params
    const embedded = searchParams.get(SearchParams.Embedded)
    Actions.initSessionForLead(auth, embedded)
      .then((value) => setInitialState(value))
      .catch((err) => setError(err))
  }, [params, searchParams])

  if (!!error) {
    return <Error error={error} />
  }

  if (!initialState) {
    return <Loading />
  }

  return (
    <StateProvider initialState={initialState}>
      <PageContainer />
    </StateProvider>
  )
}
