import { css, StyleSheet } from 'aphrodite'
import { Font, Theme } from '../../Styles'
import { IInputProps } from './Input'
import Label from './Label'

export default function TextAreaInput(props: IInputProps) {
  const { id, label, placeholder, value } = props

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => props.onChange?.(id, event.target.value)

  return (
    <div className={css(styles.container)}>
      {!!label && <Label id={id} text={label} showError={false} />}
      <textarea className={`${id}-field ${css(styles.textarea)}`} id="note" value={value} onChange={onChange} placeholder={placeholder || ''} />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  textarea: {
    boxSizing: 'border-box',
    backgroundColor: Theme.inputColor,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Theme.borderColor,
    borderStyle: 'solid',
    resize: 'none',
    height: 80,
    width: '100%',
    margin: 0,
    padding: 12,
    fontFamily: Font.Normal,
    marginBottom: 8,
    fontSize: 14,
  },
})
