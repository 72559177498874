import { css, StyleSheet } from 'aphrodite'
import { useMemo } from 'react'
import 'react-day-picker/dist/style.css'
import { TimeSlot } from '../Api'
import Header from '../components/Header'
import QuickPickSlot from '../components/QuickPickSlot'
import TimeslotPicker from '../components/TimeslotPicker'
import { useStateValue } from '../state/State'
import { Theme } from '../Styles'
import { ISectionSettings } from '../Types'

export default function SchedulerPage() {
  const [{ actions, selectedTimeslot, session, slots, showUnavailable }] = useStateValue()

  const { allowUnavailableSlots } = session

  const onToggleUnavailable = () => {
    console.log('toggle', showUnavailable)
    actions.showUnavailable(!showUnavailable)
  }

  const onSelectSlot = (value: TimeSlot) => actions.selectTimeslot(value)

  const timeZoneMatches = useMemo(() => {
    const timezone = session.timeZoneId
    return timezone === Intl.DateTimeFormat().resolvedOptions().timeZone
  }, [session])

  const suggestedSlotsSection = useMemo(() => {
    return session?.pages?.scheduler?.settings?.suggestedSlotsSection as ISectionSettings
  }, [session])

  const dateTimePickerSection = useMemo(() => {
    return session?.pages?.scheduler?.settings?.dateTimePickerSection as ISectionSettings
  }, [session])

  return (
    <div className={css(styles.container)}>
      {!timeZoneMatches && (
        <div className={css(styles.timezone)}>
          <span className={css(styles.timezoneText)}>{`Timezone: ${session.timeZoneId}`}</span>
        </div>
      )}
      {suggestedSlotsSection?.isVisible && (
        <>
          <Header title={suggestedSlotsSection?.label || ''} />
          <div className={css(styles.quickPickSlots)}>
            {!!slots?.suggestedSlots?.length &&
              slots.suggestedSlots.map((slot, i) => {
                return <QuickPickSlot key={`quickPick:${i}`} slot={slot} onClick={onSelectSlot} />
              })}
          </div>
        </>
      )}
      {dateTimePickerSection?.isVisible && (
        <>
          <Header title={dateTimePickerSection?.label || ''} />
          <TimeslotPicker
            selectedSlot={selectedTimeslot}
            slots={slots?.slots || []}
            onSelect={onSelectSlot}
            allowUnavailableSlots={!!slots?.slots && !!allowUnavailableSlots}
            showUnavailable={showUnavailable}
            onToggleUnavailable={onToggleUnavailable}
          />
        </>
      )}
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    maxWidth: 750,
    padding: 16,
  },
  quickPickSlots: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '@media (max-width: 500px)': {
      display: 'block',
    },
  },
  timezone: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: Theme.infoColor,
    width: '100%',
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
  },
  timezoneText: {
    fontSize: 14,
  },
})
