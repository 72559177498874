// import * as FullStory from '@fullstory/browser'
import { Appointment, AppointmentReq, Client, IUpdateLeadRequest, SessionConfig, TimeSlot, UpdateLeadRequest } from '../Api'
import { AuthToken, ITheme, IWindowContext, Page } from '../Types'
import { getIsLeadComplete, parseError } from '../Utils'
import { Action, ISchedulerState } from './State'

const clientId = process.env.REACT_APP_CLIENT_ID
const clientSecret = process.env.REACT_APP_CLIENT_SECRET
const idpHost = process.env.REACT_APP_IDP_HOST
const apiHost = process.env.REACT_APP_API_HOST

const defaultTheme: ITheme = {
  primaryColor: '#1565C0',
  secondaryColor: '#42A5F5',
}

export interface ISchedulerInitializerParams {
  auth: string | undefined
  embedded: string | null
  launchCode: string | undefined
  zip: string | null
}

export class Actions {
  private client: Client
  private sessionId: string
  private dispatch: React.Dispatch<Action> | undefined

  constructor(client: Client, sessionId: string) {
    this.client = client
    this.sessionId = sessionId
  }

  public static initSessionForOrg(org: string | undefined, embeddedArg: string | null) {
    const { embedded } = Actions.windowContext()
    const consolidatedEmbedded = embeddedArg ?? embedded ?? null

    return new Promise<ISchedulerState>(async (resolve, reject) => {
      try {
        if (!!org) {
          const client = await this.initClient()
          const session = await client.initSessionForOrganizationPOST(org)
          this.identifySession(session, org)
          resolve(this.createSchedulerState(client, session, consolidatedEmbedded))
        }
        reject('Invalid launch code')
      } catch (err) {
        reject(err)
      }
    })
  }

  public static async initSessionForZip(zip: string, embeddedArg: string | null) {
    const { embedded } = Actions.windowContext()
    const consolidatedEmbedded = embeddedArg ?? embedded ?? null

    const client = await this.initClient()
    const session = await client.initSessionByPostalCode(zip)
    this.identifySession(session)

    return this.createSchedulerState(client, session, consolidatedEmbedded)
  }

  public static initSessionForLead(token: string | undefined, embeddedArg: string | null) {
    const { embedded } = Actions.windowContext()
    const consolidatedEmbedded = embeddedArg ?? embedded ?? null

    return new Promise<ISchedulerState>(async (resolve, reject) => {
      try {
        const client = await this.initClient(token)
        const session = await client.initSessionForLead()
        this.identifySession(session)
        resolve(this.createSchedulerState(client, session, consolidatedEmbedded))
      } catch (err) {
        reject(err)
      }
    })
  }

  private static windowContext(): IWindowContext {
    return '__PI__' in window ? (window['__PI__'] as IWindowContext) : {}
  }

  private static initClient(token?: string) {
    return new Promise<Client>(async (resolve, reject) => {
      try {
        // access token from url
        if (!!token) {
          resolve(new Client({ token }, apiHost))
        }
        // create new access token
        const authToken = await this.getAuthToken()
        resolve(new Client({ token: authToken.access_token }, apiHost))
      } catch (err) {
        reject(err)
      }
    })
  }

  private static createSchedulerState(client: Client, session: SessionConfig, embeddedString: string | null) {
    return new Promise<ISchedulerState>(async (resolve, reject) => {
      try {
        const embedded = embeddedString === 'false' ? false : true
        let page = Page.ContactInfo
        if (!!client && !!session?.id) {
          const { appointment, lead, organization } = session
          const isLeadComplete = getIsLeadComplete(lead)
          if (isLeadComplete) {
            if (!!appointment) {
              page = Page.AppointmentConfirmation
            } else {
              page = Page.Scheduler
            }
          }
          const actions = new Actions(client, session.id)
          const theme = this.initTheme(session.primaryColorRgba)
          resolve({ actions, appointment, session, page, theme, lead, organization, embedded, showUnavailable: false })
        }
      } catch (err) {
        const message = parseError(err)
        reject(message)
      }
    })
  }

  private static getAuthToken() {
    return new Promise<AuthToken>(async (resolve, reject) => {
      try {
        let body = 'authority=' + idpHost
        body += '&client_id=' + clientId
        body += '&client_secret=' + clientSecret
        body += '&response_type=id_token%20token&'
        body += '&scope=client_app%20scheduler'
        body += '&grant_type=client_credentials'
        const resp = await fetch(idpHost + '/connect/token', {
          headers: {
            accept: 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
          },
          body,
          method: 'POST',
        })
        if (resp.status !== 200) {
          reject('Failed to authenticate')
        }
        const token: AuthToken = await resp.json()
        if (!token?.access_token) {
          reject('Invalid token')
        }
        resolve(token)
      } catch (err) {
        const message = parseError(err)
        reject(message)
      }
    })
  }

  private static initTheme(primaryColor: string | undefined) {
    let theme = defaultTheme
    if (!!primaryColor) {
      const hex = `#${primaryColor}`
      const reg = /^#([0-9a-f]{3}){1,2}$/i
      if (reg.test(hex)) {
        theme.primaryColor = hex
      }
    }
    return theme
  }

  private static identifySession(session: SessionConfig | undefined, launchCode?: string) {
    // const mainId = session?.lead?.id || session?.id || launchCode || ''
    // FullStory.identify(mainId, {
    //   organizationId: session?.organization?.id,
    //   organizationName: session?.organization?.name,
    //   sessionId: session?.id,
    //   launchCode,
    //   email: session?.lead?.email,
    //   displayName: session?.lead?.name,
    // })
  }

  public setDispatch(dispatch: React.Dispatch<Action>) {
    this.dispatch = dispatch
  }

  public showUnavailable(show: boolean) {
    console.log('showUnavailable',show);
    this.dispatch?.({ type: show ? 'showUnavailable' : 'hideUnavailable' })
  }

  public getSlots(showUnavailable: boolean) {
    this.client
      .getSlots2(this.sessionId, null, null, showUnavailable)
      .then((slots) => {
        this.dispatch?.({ type: 'setSlots', payload: slots })
      })
      .catch(this.handleError)
  }

  private handleError(error: any) {
    const payload = parseError(error)
    this.dispatch?.({ type: 'error', payload })
    console.error(payload)
  }

  public goToPage(page: Page) {
    this.dispatch?.({ type: 'goToPage', payload: page })
  }

  public async addLead(contactInfo: IUpdateLeadRequest) {
    try {
      this.dispatch?.({ type: 'loading' })
      const { leadTypeId } = Actions.windowContext()
      const promise = !leadTypeId
        ? this.client.addLead(this.sessionId, '_not_used_', contactInfo)
        : this.client.addLead2(this.sessionId, leadTypeId, contactInfo)
      const lead = await promise
      this.dispatch?.({ type: 'addLead', payload: lead })
    } catch (err) {
      this.handleError(err)
    }
  }

  public async updateLead(contactInfo: IUpdateLeadRequest) {
    try {
      this.dispatch?.({ type: 'loading' })
      const updateLeadRequest = new UpdateLeadRequest(contactInfo)
      const payload = await this.client.updateLead(this.sessionId, updateLeadRequest)
      this.dispatch?.({ type: 'updateLead', payload })
    } catch (err) {
      this.handleError(err)
    }
  }

  public selectTimeslot(timeslot: TimeSlot) {
    this.dispatch?.({ type: 'selectTimeslot', payload: timeslot })
  }

  public async addAppointment(appointmentReq: AppointmentReq) {
    this.dispatch?.({ type: 'loading' })
    this.client
      .appointment(this.sessionId, appointmentReq)
      .then((payload) => {
        this.dispatch?.({ type: 'addAppointment', payload })
      })
      .catch((err) => {
        this.handleError(err)
      })
  }

  public async deleteAppointment(appointment: Appointment | undefined) {
    if (!!appointment?.id) {
      try {
        this.dispatch?.({ type: 'loading' })
        await this.client.deleteAppointment(this.sessionId, appointment.id)
        this.dispatch?.({ type: 'deleteAppointment' })
      } catch (err) {
        this.handleError(err)
      }
    }
  }

  /*public async addNote(note: string) {
    try {
      this.dispatch?.({ type: 'loading' })
      const noteReq = new NoteReq({ content: note, contentFormat: ContentFormat.PlainText, subject: '' })
      await this.client.addNote(this.sessionId, noteReq)
      this.dispatch?.({ type: 'addNote' })
    } catch (err) {
      this.handleError(err)
    }
  }*/

  public clearError() {
    this.dispatch?.({ type: 'error', payload: '' })
  }
}
