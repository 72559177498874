import { css, StyleSheet } from 'aphrodite'
import { useMemo, useState } from 'react'
import { AppointmentReq } from '../Api'
import Button from '../components/Button'
import DetailsCard from '../components/DetailsCard'
import Header from '../components/Header'
import Modal from '../components/Modal'
import CalendarIcon from '../icons/calendar.png'
import CallIcon from '../icons/call.png'
import ClockIcon from '../icons/clock.png'
import HomeIcon from '../icons/home.png'
import MailIcon from '../icons/mail.png'
import ProfileIcon from '../icons/profile.png'
import { useStateValue } from '../state/State'
import { Theme } from '../Styles'
import { ISectionSettings, Page } from '../Types'
import { formatDate, formatLeadAddress, formatTime } from '../Utils'

export default function AppointmentConfirmationPage() {
  const [{ actions, appointment, selectedTimeslot, session, isLoading, lead }] = useStateValue()
  const [showCancelModal, setShowCancelModal] = useState(false)

  const onBookClicked = () => {
    if (!selectedTimeslot && !!appointment) {
      actions.goToPage(Page.AppointmentScheduled)
    } else {
      const appointmentReq = new AppointmentReq(selectedTimeslot)
      actions.addAppointment(appointmentReq)
    }
  }

  const onCancelClicked = () => setShowCancelModal(true)

  const confirmCancel = () => {
    actions.deleteAppointment(appointment)
    setShowCancelModal(false)
  }

  const hideCancelModal = () => setShowCancelModal(false)

  const onEditSchedule = () => actions.goToPage(Page.Scheduler)

  const onEditContactInfo = () => actions.goToPage(Page.ContactInfo)

  const cancelExistingAppointment = useMemo(() => {
    return session?.pages?.appointmentConfirmation?.settings?.cancelExistingAppointment as ISectionSettings
  }, [session])

  const scheduleAppointment = useMemo(() => {
    return session?.pages?.appointmentConfirmation?.settings?.scheduleAppointment as ISectionSettings
  }, [session])

  const scheduleAppointmentText = useMemo(() => {
    if (!!appointment && !!selectedTimeslot?.start) {
      return 'Reschedule Appointment'
    }
    return scheduleAppointment?.label || ''
  }, [appointment, scheduleAppointment, selectedTimeslot])

  const date = useMemo(() => {
    return formatDate(selectedTimeslot?.start || appointment?.start)
  }, [appointment, selectedTimeslot])

  const time = useMemo(() => {
    return formatTime(selectedTimeslot?.start || appointment?.start)
  }, [appointment, selectedTimeslot])

  const address = useMemo(() => {
    if (!!lead) {
      return formatLeadAddress(lead)
    }
    return ''
  }, [lead])

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        margin: 'auto',
        maxWidth: 500,
        padding: 16,
      },
      cancelButton: {
        fontSize: 14,
        fontWeight: 600,
        color: Theme.dangerColor,
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        width: '100%',
      },
      cancelModalText: {
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
      },
      cancelModalButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12,
      },
      cancelModalButtonNo: {
        marginRight: 12,
      },
      buttons: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
      buttonContainer: {
        marginRight: 8,
      },
    })
  }, [])

  return (
    <div className={css(styles.container)}>
      <Header title={session?.pages?.appointmentConfirmation?.header || ''} subtitle={session?.pages?.appointmentConfirmation?.subHeader || ''} />
      <DetailsCard
        title="Schedule"
        onEdit={onEditSchedule}
        rows={[
          { label: 'Date', value: date, icon: CalendarIcon },
          { label: 'Time', value: time, icon: ClockIcon },
        ]}
      />
      <DetailsCard
        title="My Information"
        onEdit={onEditContactInfo}
        rows={[
          { label: 'Name', value: lead?.name || '', icon: ProfileIcon },
          { label: 'Phone', value: lead?.phone || '', icon: CallIcon },
          { label: 'Email', value: lead?.email || '', icon: MailIcon },
          { label: 'Address', value: address, icon: HomeIcon, vertical: true },
        ]}
      />
      <div className={css(styles.buttons)}>
        {!!appointment && cancelExistingAppointment?.isVisible && (
          <div className={css(styles.buttonContainer)}>
            <Button id="cancel-appointment" text={cancelExistingAppointment?.label || ''} onClick={onCancelClicked} disabled={isLoading} />
          </div>
        )}
        {scheduleAppointment?.isVisible && (
          <Button id="schedule-appointment" text={scheduleAppointmentText} onClick={onBookClicked} disabled={isLoading || !scheduleAppointment?.isAllowed} />
        )}
      </div>
      <Modal showModal={showCancelModal}>
        <div className={css(styles.cancelModalText)}>{'Are you sure you want to cancel your appointment?'}</div>
        <div className={css(styles.cancelModalButtonContainer)}>
          <div className={css(styles.cancelModalButtonNo)}>
            <Button id="cancel-appointment-modal-no" text="No" onClick={hideCancelModal} />
          </div>
          <Button id="cancel-appointment-modal-yes" text="Yes" onClick={confirmCancel} />
        </div>
      </Modal>
    </div>
  )
}
