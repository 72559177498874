import { createHashRouter, RouterProvider } from 'react-router-dom'
import Error from './components/Error'
import LeadRoute from './routes/LeadRoute'
import OrgRoute from './routes/OrgRoute'
import ZipRoute from './routes/ZipRoute'

const router = createHashRouter([
  {
    path: '/existing/:auth',
    element: <LeadRoute />,
    errorElement: <Error />,
  },
  {
    path: '/new/:org',
    element: <OrgRoute />,
    errorElement: <Error />,
  },
  {
    path: '*',
    element: <ZipRoute />,
    errorElement: <Error />,
  },
])

export default function Router() {
  return <RouterProvider router={router} />
}
