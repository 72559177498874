import { format } from 'date-fns'
import { IOrganizationResp, IUpdateLeadRequest, Lead, SparseLead, TimeSlot } from './Api'
import { IDayPickerConfig, TimePickerWindow } from './Types'

export function parseError(error?: any) {
  let message = 'An unexpected error occurred'
  try {
    if (!!error?.response) {
      const json = JSON.parse(error.response)
      if (!!json?.message?.length) {
        message = json.message
      } else if (!!json?.title?.length) {
        message = json.title
      }
    } else if (!!error?.message) {
      message = error.message
    }
  } catch (jsonParseError) {}
  return message
}

export function getDaysArray(start: Date, end: Date) {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt))
  }
  return arr
}

export function getIsSameDay(date1: Date | undefined, date2: Date | undefined) {
  if (!date1 || !date2) return false
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
}

export const validateNameRegex = /(\w.+\s).+/

export const validateEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function getIsContactInfoValid(contactInfo: IUpdateLeadRequest) {
  const { name, phone, email, address, city, postalCode, state, country } = contactInfo
  if (!name || !phone || !email || !address || !city || !postalCode || !state || !country || !phone) {
    return false
  }
  if (!validateNameRegex.test(name)) {
    return false
  }
  if (!validateEmailRegex.test(email)) {
    return false
  }
  return true
}

export function getIsLeadComplete(lead: SparseLead | undefined) {
  return !!lead && lead.name && lead.email && lead.phone && lead.address && lead.city && lead.postalCode && lead.state
}

export function formatDateTimeLong(date: Date | undefined) {
  if (!date) {
    return ''
  }
  return format(date, 'EEEE, MMMM d, h:mm aa')
}

export function formatDateTimeShort(date: Date | undefined) {
  if (!date) {
    return ''
  }
  return format(date, 'EEE, MMM d, h:mm aa')
}

export function formatDate(date: Date | undefined, long?: boolean) {
  if (!date) {
    return ''
  }
  return format(date, long ? 'EEEE, MMMM d' : 'EEE, MMM d')
}

export function formatTime(date: Date | undefined) {
  if (!date) {
    return ''
  }
  return format(date, 'hh:mm aa')
}

export function formatOrgAddress(org: IOrganizationResp) {
  if (!org?.address || !org?.city || !org?.state) {
    return ''
  }
  return `${org.address}, ${org.city}, ${org.state} ${org?.postalCode || ''}`
}

export function formatLeadAddress(lead: Lead) {
  if (!lead?.address || !lead?.city || !lead?.state) {
    return ''
  }
  return `${lead.address}, ${lead.city}, ${lead.state} ${lead?.postalCode || ''}`
}

export function getDayPickerConfig(slots: TimeSlot[], window: TimePickerWindow, currentlySelectedDate?: Date) {
  // Filter slots by selected window
  const selectedWindowSlots = slots.filter((timeSlot) => {
    const date = timeSlot.start
    if (!date) {
      return false
    }

    const hours = date.getHours()
    const minutes = date.getMinutes()
    if (window === TimePickerWindow.All) {
      return true
    } else if (window === TimePickerWindow.Morning) {
      return hours < 12 || (hours === 12 && minutes === 0)
    } else if (window === TimePickerWindow.Afternoon) {
      return (hours === 12 && minutes === 30) || (hours > 12 && hours < 16) || (hours === 16 && minutes === 0)
    } else if (window === TimePickerWindow.Evening) {
      return (hours === 16 && minutes === 30) || hours > 16
    }
    return false
  })
  // Calculate which days are disabled
  const fromDate = new Date()
  const toDate = !!selectedWindowSlots?.length ? selectedWindowSlots[selectedWindowSlots.length - 1].start || new Date() : new Date()
  const daysArray = getDaysArray(fromDate, toDate)
  const disabledDays: Date[] = []
  const validDays: Date[] = []
  let newSelectedDate = currentlySelectedDate
  daysArray.forEach((day) => {
    const dayHasSlots = selectedWindowSlots.some((slot) => getIsSameDay(day, slot?.start))
    if (!dayHasSlots) {
      disabledDays.push(day)
      if (!!newSelectedDate && getIsSameDay(newSelectedDate, day)) {
        newSelectedDate = undefined
      }
    } else {
      validDays.push(day)
    }
  })
  const firstValidDay = !!validDays?.length ? validDays[0] : fromDate
  // Create config
  const config: IDayPickerConfig = {
    slots: selectedWindowSlots,
    fromDate,
    toDate,
    disabledDays,
    selectedDate: newSelectedDate || firstValidDay,
    window,
  }
  return config
}
