import { useMemo } from 'react'
import { InputId } from '../../Types'
import { validateEmailRegex } from '../../Utils'
import Input, { IBaseInputProps } from './Input'

export default function EmailInput(props: IBaseInputProps) {
  const { onChange, value } = props

  const hasError = useMemo(() => {
    if (!value) {
      return true
    }
    return !validateEmailRegex.test(value)
  }, [value])

  return <Input id={InputId.Email} label="Email *" placeholder="Write here..." onChange={onChange} value={value} hasError={hasError} />
}
